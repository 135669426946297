html {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
body {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  line-height: 1.42857143;
  color: #333;
  background-color: #f8f8f8;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// Util classes
.hover-point {
  cursor: pointer;
}

@media (min-width: 768px) {
  .sidebar {
    margin-top: 0;
  }
}

// 404, 500 error pages
#error {
  position: relative;
  top: 13em;
  text-align: center;

  .error-section {
    background-color: white;
    padding: 40px;
  }
}

// Loader
.loader-container {
  text-align: center;
  margin-top: 15%;
}


// Buttons
.btn {
  margin-right: 8px !important;

  &.btn-brand {
    background: #4DA55B;
    color: #fff;
  }

  &.btn-fb {
    background: #3b5998 !important;
    color: #fff !important;
  }

  &.btn-tw {
    background: #1da1f2 !important;
    color: #fff !important;
  }

  &.btn-fb, &.btn-tw {
    border: 1px solid transparent !important;
    padding: 5px 10px !important;
    font-size: 12px !important;
  }

  &.btn-li {
    background: #0077b5;
    color: #fff;
  }
}

.page-header {
  display: inline-block;
  width: 100%;
    h1 {
      display: inline;
    }
}

.margin-top-8 {
  margin-top: 8px;
}

.logo-holder img{
  width: 186px;
  padding: 5px;
}

#wrapper {
  width: 100%;
}
#page-wrapper {
  padding: 0 15px;
  min-height: 568px;
  background-color: white;
}
@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 250px;
    padding: 0 30px;
    border-left: 1px solid #e7e7e7;
  }
}

@media (min-width: 992px){
  .col-md-6{
    float: left;
  }
}
