.google-login{
    display: flex;
    flex-direction: row;

    span{
      max-width: 200px;
      height: 18px;
      margin: 6px;
      padding: 4px;
    }
}
