#officer-edit {
  box-shadow: none;

  .image-holder {
    width: 176px;
    height: 176px;
    margin-right: 50px;
    border-radius: 4px;
    overflow: hidden;
    background-size: cover;
  }

  .file-upload {
    margin-top: 50px;

    .image-label {
      margin-top: 10px;
      width: 100%;

      .btn-default {
        border: 1px solid #ccc;
      }
    }

    .help-note {
      width: 190px;
    }
  }

  .social-profile {
    margin: 1em 0;
    display: flex;
    flex-direction: row;

    & > * {
      margin: 0 4px;
    }
    * > .form-control, button {
      height: 40px !important;
    }
    .url-container {
      flex-grow: 1;
    }

  }

  .note {
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    width: 100%;
    position: relative;
    top: 4px;
    left: 2px;
  }

  .inputFile {
    padding: 7px 50px;
    border-radius: 8px;
    border-color: white;
  }

  .save-officer {
    padding: 6px 47px;

  }

  .address-list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .address-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
    background-color: #f9f9f9;
    border-radius: 4px;
    border: 1px black;
    margin-bottom: 8px;
    text-decoration: none; /* remove underline from anchor tag */
    color: #333; /* add color to text */
  }

  .address-bar:last-child {
    margin-bottom: 0;
  }

  .address-radio {
    margin: 8px;
  }

  .address-label {
    font-size: 16px;
    font-weight: 600;
  }

  /* Tablet and desktop styles */

  @media screen and (min-width: 768px) {
    .address-list {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .address-bar {
      width: 100%;
      margin-bottom: 16px;
    }
  }
  .address-bar:hover {
    background-color: #ddd;
    cursor: pointer;
  }
}
