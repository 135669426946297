#reviews {
  // stars under loan officer
  $star-filled-in: #F2C94C;
  $star-empty: #FBE8AF;
  .stars {
    display: inline-block;
    font-size: 17px;

    .star {
      display: inline-block;
      color: $star-empty;
    }

    &.stars--1 {
      .star:nth-child(-n+1) {
        color: $star-filled-in;
      }
    }

    &.stars--2 {
      .star:nth-child(-n+2) {
        color: $star-filled-in;
      }
    }

    &.stars--3 {
      .star:nth-child(-n+3) {
        color: $star-filled-in;
      }
    }

    &.stars--4 {
      .star:nth-child(-n+4) {
        color: $star-filled-in;
      }
    }

    &.stars--5 {
      .star:nth-child(-n+5) {
        color: $star-filled-in;
      }
    }
  }

  .review-actions {
    margin-top: 20px;
  }

  .no-reviews {
    padding-top: 80px;
    min-height: 200px;
    font-size: 19px;
  }

  .review-comment-box {
    padding: 23px 3px;
    .btn {
      padding: 5px 35px;
    }
  }

  .reply-box {
    position: relative;
    top: 10px;
    background: #fafafa;
    border: 1px solid #dedede;
    border-radius: 4px;
    padding: 15px;
    margin: 10px 0px;
  }

  .reply-buttons {
    padding: 2px;
    position: relative;
    left: 10px;
  }

  .dl-horizontal {
    .dl-horizontal-row {
      margin-bottom: 5px;
    }

    .review-body {
      blockquote {
        border-color: rgba(#4DA55B, 0.65);
      }
    }
  }
}
