.sidebar .sidebar-nav.navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar .sidebar-search {
    padding: 15px;
  }
  .sidebar ul li {
    border-bottom: 1px solid #e7e7e7;
  }
  .sidebar ul li a.active {
    background-color: #eeeeee;
  }
  .sidebar .arrow {
    float: right;
  }
  .sidebar .fa.arrow:before {
    content: "\f104";
  }
  .sidebar .active > a > .fa.arrow:before {
    content: "\f107";
  }
  @media (min-width: 768px) {
    .sidebar {
      z-index: 1;
      position: absolute;
      width: 250px;
      margin-top: 51px;
    }
  }
