$width: 200px;

.react-select-container {
    width: $width;

    .react-select__control {
        width: $width;
    }
    .react-select__menu {
        width: $width;
    }

    .react-select__single-value, .react-select__option {
        display: flex;
        align-items: center;
    }

    i, svg {
        margin-right: 8px;
    }
    
    .icon-container {
        display: flex;
        align-items: center;
    }
}