.auth-container {
  margin-top: 15%;

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .login-error {
    margin: 8px 15px 0px 15px;
  }
}
